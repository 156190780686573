import React, {useMemo} from 'react';
import { useTable, useSortBy, usePagination } from "react-table";

function MyCell({ value }) {
    return <a href={value} >Pobierz plik</a>
  }
export default function Reacttable2({ columns, data }) {

 const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable({
    columns,
    data
  },
  useSortBy,
  usePagination);
  return (
    <div>
      
    <table {...getTableProps()} style={{ border: '#2DA2BF',  }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} tyle={{
                                 
            background: 'white',
           color: '#2DA2BF',
           borderstyle: 'solid',
           fontWeight: 'normal',
         }}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{
                                 
                                  background: 'white',
                                 color: '#2DA2BF',
                                 border: '#2DA2BF',
                                 borderstyle: 'solid',
                                 fontWeight: 'normal',
                               }} >{column.render("Header")}<span>
                                 {column.isSorted ? (column.isSortedDesc ? '↓' : '↑'): ' '}
                                 </span></th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}style={{
              padding: '10px',
           border: '#2DA2BF',
          
           borderstyle: 'solid',
           background: '#D2EEF4',
             }}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()} style={{
                                     padding: '10px',
                                     fontSize: '90%',
                                  border: '#2DA2BF',
                                  borderstyle: 'solid',
                                  background: '#D2EEF4',
                                    }}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
    <span style={{Align: 'center'}}>
      <button style={{Align: 'center'}} onClick={() => previousPage()} disabled={!canPreviousPage}>Poprzednia strona</button>
      <button style={{Align: 'center'}} onClick={() => nextPage()} disabled={!canNextPage}>Następna strona</button>
      </span></div>
  )
  ; }
