import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import authentication from 'react-azure-b2c';

import jwt from 'jwt-decode';

authentication.initialize({
  instance: 'https://artoeusers.b2clogin.com/', 
  tenant: 'artoeusers.onmicrosoft.com',
  signInPolicy: 'B2C_1_onlylogin',
  clientId: '7e3175ee-5356-4c39-9343-f14eb8faa285',
  cacheLocation: 'sessionStorage',
  scopes: ['https://artoeusers.onmicrosoft.com/API/user_impersonation'],
redirectUri: 'https://artoe.z6.web.core.windows.net/',
//redirectUri: 'http://localhost:3000',
// postLogoutRedirectUri: window.location.origin,
postLogoutRedirectUri:'https://artoe.z6.web.core.windows.net/',
//postLogoutRedirectUri:'https://b2b.hurtowniamultistore.pl/',
  validateAuthority: false
});

authentication.run(() => {
 
  var decoded = jwt(authentication.getAccessToken().accessToken);
 
  console.log(decoded)
  ReactDOM.render(<React.StrictMode>
    <App user={decoded.emails[0]} company={decoded.city}/>
  </React.StrictMode>,
  document.getElementById('root'));

});

