import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import authentication from 'react-azure-b2c';

function LogoutButton(user) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const anchorOpen = Boolean(anchorEl);

  return (
            <div style={{marginLeft: '95%', position: 'fixed'}}>
              <IconButton
                aria-owns={anchorOpen ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={handleMenu}
                color="black"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={anchorOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={() => authentication.signOut()}>Wyloguj {user.user}</MenuItem>
              </Menu>
            
            </div>

  );
}

/*
LogoutButton.propTypes = {
    logOutAction: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    logOutAction: makeAction(auth.LOG_OUT),
};

export default  connect(
    mapStateToProps,
    mapDispatchToProps
)(LogoutButton);
*/
export default LogoutButton
