import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import './App.css';
import logo from "./logo-multistore-hurt.png"
import LogoutButton from "./logoutbutton";
import Reacttable2 from "./reactTable";

function MyCell({ value }) {
  return <a href={value} >Pobierz plik</a>
}

  function App(user) {
    console.log(user)
    const numberStyle = {style: 'currency', currency: 'PLN'}
    const columns = [
      {
        Header: "Data",
        accessor: "date",
        Cell: props => <div style={{textAlign: 'right'}}>{new Date(props.value).toISOString().split('T')[0]}</div>,
        direction: 'asc'
      },
      {
        Header: "numer faktury",
        accessor: "number",
      },
      {
        Header: () => "kwota",
        accessor: "amount",
        style: {
          textAlign: 'center'
      },
        Cell: props => <div style={{textAlign: 'right'}}>{new Intl.NumberFormat('pl-PL', numberStyle).format(props.value)}</div>
      },
      {
        Header: "Numer zamówienia",
        accessor: "reference",
      },
      {
        Header: "link",
        accessor: "link",
        Cell:MyCell
      }]
      const data2 = [{"nr faktury":{"nr":"12345", "link": "http://google.com"}}]
      const [data, setData] = useState([]);

      useEffect(() => {
        (async () => {
          const result = await axios.post("https://listinvoices.azurewebsites.net/api/listInvoices?code=iTyj8A0iKPcm8ttr8u/CEks/TI1ANdzbitTUaIPTeKaWranI2DZs8g==",{"email":user.user});
          setData(result.data);
        })();
      }, [user]);

   return(
   <div>
     <img alt="Multistore" src={logo} style={{marginBottom: '15px', marginTop: '15px', alignSelf: 'left'}}/>
     <LogoutButton user={user.user}/>
     <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }}>
    
 <div className="app">
 <div className="containers" >
   <p>lista faktur wystawionych przez multistore dla {user.company} </p>
  <Reacttable2 columns={columns} data={data} sortable={true} itemsPerPage={2} pageButtonLimit={5}></Reacttable2>

 </div>
 
 </div></div></div>
 ); 
}
function toCurrency(numberString) {
  
  return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(numberString)
}
export default App;